import React from 'react';
import Layout from 'components/Layout';
import { Router } from '@reach/router';
import DashboardView from 'controlTower/components/DashboardView';

export default function dashboard(props) {
  return (
    <Layout {...props} useNodeSelector>
      <Router style={{ height: '100%', position: 'relative' }}>
        <DashboardView
          path="/ct/dashboard/:dashboardType"
          style={{ height: '100%', position: 'relative' }}
        />
      </Router>
    </Layout>
  );
}
