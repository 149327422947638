import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import WidgetContainer from 'controlTower/components/WidgetContainer';
import { widgetsMapping } from 'controlTower/ctConstants';
import React, { useMemo } from 'react';
import { useGState } from 'state/store';
import { useSettings } from 'utils/customHooks';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    position: 'relative',
    width: '100%',
  },
  gridContainer: { position: 'relative' },
  gridItem: {
    position: 'relative',
    height: 400,
    [theme.breakpoints.down('sm')]: {
      height: 650,
    },
  },
  widgetContainer: { position: 'relative', height: '100%' },
}));

export default function DashboardView({ dashboardType }) {
  const classes = useStyles();
  const settings = useSettings();
  const node = useGState((s) => s.node);

  const widgets = useMemo(
    () =>
      widgetsMapping
        .filter(({ dashboard }) => dashboard === dashboardType)
        .filter(({ id }) =>
          settings[`${dashboardType}Dashboard`]
            ? settings[`${dashboardType}Dashboard`].includes(id)
            : true
        )
        .sort((a, b) =>
          settings[`${dashboardType}Dashboard`]
            ? settings[`${dashboardType}Dashboard`].indexOf(a.id) -
              settings[`${dashboardType}Dashboard`].indexOf(b.id)
            : 0
        ),
    [dashboardType, settings]
  );
  return (
    <div className={classes.root}>
      <Grid container className={classes.gridContainer}>
        {widgets.map(({ name, url, getLeafUrl, widget, widgetId, salesmanType }, i) => (
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={i} className={classes.gridItem}>
            <WidgetContainer
              name={name}
              widgetId={widgetId}
              url={url}
              getLeafUrl={getLeafUrl}
              Widget={widget}
              id={node?.id}
              className={classes.widgetContainer}
              salesmanType={salesmanType}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
